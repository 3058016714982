import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { Button, StyleSheet, Text, TextInput, View, Image } from "react-native";
import { Icon } from "react-native-paper";
import React from "react";
import forest1 from "./assets/answers/forest-old-no-1.json";
import forest2 from "./assets/answers/forest-old-yes-1.json";
import noAnswer from "./assets/answers/forest-old-no-answer-1.json";
import { useState } from "react";
import * as Speech from "expo-speech";
import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import ForestWizard from "./src/pages/ForestWizard";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { PaperProvider } from "react-native-paper";
import Home from "./src/pages/Home";
import FireWizard from "./src/pages/FireWizard";

const Tab = createBottomTabNavigator();

export default function App() {
  return (
    <PaperProvider>
      <NavigationContainer>
        <Tab.Navigator
          screenOptions={{
            headerTintColor: "#ffffff",
            headerStyle: {
              backgroundColor: "#262120",
              borderColor: "#262120",
            },
            tabBarActiveTintColor: "#ffffff",
            tabBarInactiveTintColor: "#ffffff",
            tabBarActiveBackgroundColor: "#262120",
            tabBarInactiveBackgroundColor: "#262120",
            tabBarStyle: {
              backgroundColor: "#262120",
              borderColor: "#262120",
            },
          }}
        >
          <Tab.Screen
            options={{
              tabBarIcon: ({ size, focused, color }) => {
                return <Icon source="home" size={size} color={color} />;
              },
            }}
            name="Home"
            component={Home}
          />
          <Tab.Screen
            name="Forest Wizard"
            component={ForestWizard}
            options={{
              tabBarIcon: ({ size, focused, color }) => {
                return <Icon source="tree" size={size} color={color} />;
              },
            }}
          />
          <Tab.Screen
            name="Fire Wizard"
            component={FireWizard}
            options={{
              tabBarIcon: ({ size, focused, color }) => {
                return <Icon source="fire" size={size} color={color} />;
              },
            }}
          />
        </Tab.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#003300",
    alignItems: "center",
    justifyContent: "center",
  },
});
