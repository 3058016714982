import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  View,
  Image,
  ImageBackground,
  ScrollView,
} from "react-native";
import { Card, Text, TextInput, Button, Title } from "react-native-paper";
import React from "react";
import forest1 from "../../assets/answers/forest-old-no-1.json";
import forest2 from "../../assets/answers/forest-old-yes-1.json";
import noAnswer from "../../assets/answers/forest-old-no-answer-1.json";
import { useState } from "react";
import * as Speech from "expo-speech";

const getMessages = (num) => {
  const forest3 = forest1.concat(forest2);
  const messages = forest3[num].message;
  return messages;
};

const getnumofjson = () => {
  const num = forest1.length + forest2.length;
  return num;
};

const generateRandomNumber = (top) => {
  const min = 1;
  const max = top;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber;
};

const getNoAnswer = () => {
  const min = 1;
  const max = noAnswer.length;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const messages = noAnswer[randomNumber].message;
  console.log(messages);
  console.log(randomNumber);
  return messages;
};

//"Microsoft Luke Online (Natural) - English (South Africa)"
//"Microsoft Liam Online (Natural) - English (Canada)"
//"Microsoft Ryan Online (Natural) - English (United Kingdom)"
//"Microsoft Andrew Online (Natural) - English (United States)"
const speakMessage = (message) => {
  const options = {
    language: "en-US",
    pitch: 100,
    rate: 0.9,
    voice: "Microsoft Ryan Online (Natural) - English (United Kingdom)",
  };
  Speech.speak(message, options);
};

const getVoices = async () => {
  const voices = await Speech.getAvailableVoicesAsync();
  console.log(voices);
};

export default function ForestWizard() {
  //getVoices();
  const [message, setMessage] = useState("");
  const [prevMessage, setPrevMessage] = useState("");
  const [inputVal, setInputVal] = useState("");
  return (
    <ImageBackground
      style={{ height: "100%" }}
      source={require("../../assets/images/forest-4k.png")}
    >
      <ScrollView>
        <View style={{ margin: 20, zIndex: 2000 }}>
          <Card>
            <Card.Content>
              <Title style={{ fontSize: 30 }}>
                Ask Hawthorn the Forest Wizard!
              </Title>
              <TextInput
                multiline
                style={{ marginVertical: 20 }}
                value={inputVal}
                onChangeText={(text) => setInputVal(text)}
              />
              <Text style={{ color: "white", fontSize: 25, margin: 20 }}>
                {message}
              </Text>
            </Card.Content>
            <Card.Actions>
              <Button
                title="Ask!"
                onPress={() => {
                  if (inputVal !== "") {
                    const mess = getMessages(
                      generateRandomNumber(getnumofjson())
                    );
                    console.log(mess);
                    setMessage(mess);
                    speakMessage(mess);
                    setPrevMessage(mess);
                    return;
                  } else {
                    const mess = getNoAnswer();
                    console.log(mess);
                    setMessage(mess);
                    speakMessage(mess);
                    return;
                  }
                }}
                style={{ backgroundColor: "#009900" }}
              >
                <Text style={{ color: "white" }}>Ask!</Text>
              </Button>
            </Card.Actions>
          </Card>
        </View>
        <Image
          style={{
            height: "50vh",
            width: "50vh",
            left: "20vw",
          }}
          source={require("../../assets/images/forestwizard.png")}
        />
        <StatusBar style="auto" />
      </ScrollView>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    bottom: 20,
  },
});
