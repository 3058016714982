import React from "react";
import {
  View,
  StyleSheet,
  Image,
  ImageBackground,
  ScrollView,
} from "react-native";
import { Button, Card, Title, Paragraph } from "react-native-paper";

const Home = ({ navigation }) => {
  return (
    <ScrollView>
      <ImageBackground source={require("../../assets/images/map-4k.png")}>
        <View style={styles.container}>
          <View style={styles.cardStlye}>
            <Card>
              <Card.Content>
                <Title>Welcome to the Fantasy Dashboard!</Title>
                <Paragraph>
                  Explore the magical world of your imagination.
                </Paragraph>
              </Card.Content>
              <Card.Actions>
                <Button onPress={() => navigation.navigate("Forest Wizard")}>
                  Ask a Forest Wizard
                </Button>
              </Card.Actions>
            </Card>
          </View>
          <View style={styles.cardStlye}>
            <Card>
              <Card.Content>
                <Title>Welcome to the Fantasy Dashboard!</Title>
                <Paragraph>
                  Explore the magical world of your imagination.
                </Paragraph>
              </Card.Content>
              <Card.Actions>
                <Button onPress={() => navigation.navigate("Fire Wizard")}>
                  Ask a Fire Wizard
                </Button>
              </Card.Actions>
            </Card>
          </View>
        </View>
      </ImageBackground>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "start",
    alignItems: "center",
    padding: 16,
    backgroundSize: "cover",
    minHeight: "100vh",
  },
  cardStlye: {
    margin: 10,
  },
});

export default Home;
